import './booking-page.css';
import { useEffect, useRef, useState } from 'react';
import { OfflineBookingPage } from './components/offline-booking-page';
import { OnlineBookingPage } from './components/online-booking-page';
import { OpportunityComplete, CaseInfo, CaseStatus, Result, Slot, defaultCaseInfo } from '../../dto/model';
import { GetAccessToken, HasRole, Role } from '../../utils/auth-utils';
import { useMsal } from '@azure/msal-react';
import { scopes } from '../../authConfig';
import { bookingPageService } from '../../services/booking-page-service';
import { Header } from '../header/header';
import { HeaderBookingPage } from '../header/components/header-booking-page';
import { OnlineBookingNoCasesPage } from './components/online-booking-no-cases-page';
import { Alert, Button, Spin, notification } from 'antd';
import { strings } from '../../lang';
import { wait } from '@testing-library/user-event/dist/utils';
import { ConfirmationPage } from './components/confirmation-page';
import CaseScript from './components/case-script';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { OnlineRecordingBookingPage } from './components/online-recording-booking-page';
import { salesAppointmentsService } from '../../services/sales-appointments-service';
import { useSalesChannelState } from '../../store/header-state';
import { useLocation, useNavigate } from 'react-router-dom';

export const BookingPage = () => {
  const [caseInfo, setCaseInfo] = useState<CaseInfo | null>(defaultCaseInfo);
  const [cancelledSlot, setCancelledSlot] = useState<Slot | null>(null);
  const [error, setError] = useState<any>();
  const { instance, inProgress, accounts } = useMsal();
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [displayConfirmationPage, setDisplayConfirmationPage] = useState<boolean>(false);
  const [bookedSlot, setBookedSlot] = useState<Slot>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [retryCountdown, setRetryCountdown] = useState<number>();
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const sttRootUrl = (process as any).env.REACT_APP_STT_ROOT_URL;
  const [caseStatus, setCaseStatus] = useState<CaseStatus>(CaseStatus.None);
  const [inputCaseComments, setInputCaseComments] = useState<string>('');
  const salesChannel = useSalesChannelState((state) => state.salesChannel);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [recordingComplete, setRecordingComplete] = useState<boolean>(false);
  dayjs.extend(utc);

  const userShouldBeRecorded = HasRole(accounts, Role.Recorded);

  const location = useLocation();
  const inboundCenter = location?.state?.inboundCenter;
  const navigate = useNavigate();

  //register the event listener that stops users from refreshing the page if they have data in it
  useEffect(() => {
    if (!inboundCenter) {
      if (isOnline) {
        window.addEventListener('beforeunload', handleBeforeUnload);
      } else {
        // TODO: Investigate why it does not remove the event listener on going offline (isOnline = false)
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    }
  }, [isOnline]);

  useEffect(() => {
    if (inboundCenter) {
      onOnlineChanged(inboundCenter);
    }
  }, [inboundCenter]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const assignedCaseResult = await bookingPageService.getAssignedCase(token.accessToken, salesChannel!);
      if (assignedCaseResult.isSuccess) {
        setIsOnline(true);
        setCaseInfo(assignedCaseResult.data);
        if (assignedCaseResult.data.bookingStatus === 'ReschedulingNeeded') {
          const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
          const cancelledSlotResponse = await salesAppointmentsService.getLatestSlot(token.accessToken, assignedCaseResult.data.opportunityId, salesChannel);
          if (cancelledSlotResponse.isSuccess) {
            setCancelledSlot(cancelledSlotResponse.data);
          } else {
            setCancelledSlot(null);
          }
        } else {
          setCancelledSlot(null);
        }
      }
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (userShouldBeRecorded && isOnline && caseStatus === CaseStatus.None) {
      setAudioChunks([]);
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          recorder.start();
          const chunks = [] as Blob[];

          recorder.ondataavailable = (event: BlobEvent) => {
            chunks.push(event.data);
          };

          recorder.onstop = () => {
            setAudioChunks([]);
            stream.getTracks().forEach((track) => track.stop());
            setAudioChunks(chunks);
            setRecordingComplete(true);
          };

          mediaRecorder.current = recorder;
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error);
        });
    }

    if (userShouldBeRecorded && caseStatus !== CaseStatus.None) {
      if (caseStatus !== CaseStatus.NotReached && caseStatus !== CaseStatus.WrongNumber) {
        mediaRecorder.current?.stop();
      }
    }
  }, [caseStatus, isOnline]);

  useEffect(() => {
    if (recordingComplete && caseStatus !== CaseStatus.None && caseStatus !== CaseStatus.NotReached && caseStatus !== CaseStatus.WrongNumber) {
      console.log('Recording complete');
      console.log(audioChunks);
      handleStopRecording(getCaseStatusString(caseStatus), salesChannel!, caseInfo?.opportunityId, audioChunks);
      setRecordingComplete(false);
      setAudioChunks([]);
      setCaseStatus(CaseStatus.None);
    }
  }, [audioChunks, recordingComplete]);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = ''; // used for backward compatibility
  };

  function getCaseStatusString(status: CaseStatus): string {
    switch (status) {
      case CaseStatus.None:
        return 'None';
      case CaseStatus.Booked:
        return 'Booked';
      case CaseStatus.ReachedButNotBooked:
        return 'ReachedButNotBooked';
      case CaseStatus.NotReached:
        return 'NotReached';
      case CaseStatus.CustomerLost:
        return 'CustomerLost';
      case CaseStatus.WrongNumber:
        return 'WrongNumber';
      default:
        return 'Unknown';
    }
  }

  const handleCaseAssignemnt = async (assignedCaseResult: Result<CaseInfo>) => {
    setCaseStatus(CaseStatus.None);
    if (assignedCaseResult.isSuccess) {
      const assignedCase = assignedCaseResult.data;
      setCaseInfo(assignedCase);
      if (assignedCase.bookingStatus === 'ReschedulingNeeded') {
        const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
        const cancelledSlotResponse = await salesAppointmentsService.getLatestSlot(token.accessToken, assignedCase.opportunityId, salesChannel);
        if (cancelledSlotResponse.isSuccess) {
          setCancelledSlot(cancelledSlotResponse.data);
        } else {
          setCancelledSlot(null);
        }
      } else {
        setCancelledSlot(null);
      }
    } else if (assignedCaseResult.errors && assignedCaseResult.errors[0].includes('NO_CASE_AVAILABLE')) {
      const errorDeserialized = JSON.parse(assignedCaseResult.errors[0]);
      const errorDate = dayjs(errorDeserialized.RetryIn).format('YYYY-MM-DDTHH:mm:ss');
      // Get the current UTC time
      const nowUtc = dayjs().utc().format('YYYY-MM-DDTHH:mm:ss');
      // Calculate the difference in milliseconds
      const differenceInSeconds = dayjs(errorDate).diff(nowUtc, 'milliseconds');
      setRetryCountdown(differenceInSeconds);
      setCaseInfo(null);
    } else {
      setCaseInfo(null);
      throw assignedCaseResult.errors;
    }
  };

  const handleStopRecording = async (bookingStatus: string | undefined, salesChannel: string, opportunityId: string | undefined, chunksToSend: Blob[]) => {
    if (isOnline && bookingStatus && typeof opportunityId === 'string' && chunksToSend.length > 0) {
      const audioBlob = new Blob(chunksToSend, { type: 'audio/wav' });
      const arrayBuffer = await audioBlob.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      try {
        const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
        const locale = 'de-DE';
        const response = await fetch(`${sttRootUrl}/api/v1/calls/transcriptions`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
            'enpal-user-id': token.account.localAccountId,
            'enpal-correlation-id': '',
            'enpal-opportunity-id': opportunityId,
            'enpal-language-locale': locale,
            'enpal-booking-state': bookingStatus,
            'enpal-sales-channel': salesChannel,
            'Content-Type': 'application/octet-stream',
          },
          body: uint8Array,
        });
        if (!response.ok) {
          console.error(`Failed to send recording to STT: ${response}`);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  /**
   * Handles the online changing on the header
   * @param isOnline wheter the online flag is true or false on the header
   */
  const onOnlineChanged = async (isOnline: boolean) => {
    try {
      setCaseStatus(CaseStatus.None);
      setError(undefined);
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      if (isOnline) {
        setIsLoading(true);
        const assignedCaseResult = await bookingPageService.getOrAssignPriorityCase(token.accessToken!, salesChannel!);
        handleCaseAssignemnt(assignedCaseResult);
      } else {
        if (inputCaseComments) {
          const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
          await bookingPageService.addCaseComment(caseInfo?.caseId!, caseInfo?.opportunityId!, inputCaseComments, salesChannel, token.accessToken);
        }
        if (userShouldBeRecorded) {
          mediaRecorder.current?.requestData();
          mediaRecorder.current?.stop();
          mediaRecorder.current = null;
          setAudioChunks([]);
        }
        setDisplayConfirmationPage(false);
        await bookingPageService.releaseCase(caseInfo?.caseId ?? '', caseInfo?.opportunityId ?? '', salesChannel, token?.accessToken!);
        setCaseInfo(null);
      }
      setIsLoading(false);
      setIsOnline(isOnline);
    } catch (e: any) {
      setError(e);
      throw e;
    }
  };

  const onTryFetchNextCase = async () => {
    if (inboundCenter !== undefined) {
      return;
    }

    try {
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const assignedCaseResult = await bookingPageService.getOrAssignPriorityCase(token.accessToken!, salesChannel!);
      handleCaseAssignemnt(assignedCaseResult);
    } catch (e) {
      setError(e);
    }
  };

  const onRefreshCountdown = async () => {
    try {
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const updateCaseResult = await bookingPageService.updateCaseAssignment(caseInfo?.caseId!, salesChannel, token.accessToken!);
      if (updateCaseResult.isSuccess) {
        setCaseInfo({ ...caseInfo!, expireAt: updateCaseResult.data });
      }
    } catch (e) {
      setError(e);
    }
  };

  const header = (
    <Header
      optionalComponent={
        <HeaderBookingPage
          inboundCenter={inboundCenter}
          isOnline={isOnline}
          caseId={caseInfo?.caseId!}
          caseExpiration={displayConfirmationPage ? undefined : caseInfo?.expireAt}
          onOnlineChanged={onOnlineChanged}
          onRefreshCountdown={onRefreshCountdown}
        />
      }
    />
  );

  const onBackToInbound = async (isSuccessful: boolean) => {
    await onOnlineChanged(false);
    navigate('/inbound', {
      state: {
        isSuccess: isSuccessful,
      },
    });
    setBookedSlot(undefined);
  };

  const confirmationPageOpenNextCase = async (caseComment: string | undefined) => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    if (caseComment) {
      await bookingPageService.addCaseComment(caseInfo?.caseId!, caseInfo?.opportunityId!, caseComment, salesChannel, token.accessToken);
    }
    const nextCase = await bookingPageService.getOrAssignPriorityCase(token.accessToken, salesChannel!);

    // send recording for booked cases
    if (userShouldBeRecorded) {
      setCaseStatus(CaseStatus.Booked);
      mediaRecorder.current?.requestData();
    }
    await wait(1000);

    handleCaseAssignemnt(nextCase);

    setDisplayConfirmationPage(false);
  };

  if (error) {
    return (
      <>
        {header}
        <div className="container">
          <Alert type="error" message={strings.errorOccurred} description={error?.message ?? error}></Alert>
        </div>
      </>
    );
  }

  const openErrorNotification = (isCaseNotFound: boolean) => {
    notification.open({
      key: 'errorNotification',
      message: strings.errorOccurred,
      description: (
        <>
          <p>{isCaseNotFound ? strings.caseNotFoundErrorDescription : strings.genericCloseCaseErrorDescription}</p>
          <p>{strings.youCanGetANewCase}</p>
          <Button
            type="primary"
            onClick={async (_) => {
              setIsLoading(true);
              try {
                notification.destroy('errorNotification');
                await confirmationPageOpenNextCase(undefined);
                await wait(500);
              } catch (e: any) {
                setCaseInfo(null);
                setError(e);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            {strings.getNewCase}
          </Button>
        </>
      ),
      placement: 'topRight',
      duration: 0,
      type: 'error',
    });
  };

  const handleCaseClosed = async (caseComplete: OpportunityComplete, bookedSlot?: Slot) => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    const closeCaseResult = await bookingPageService.closeCase(caseComplete, caseInfo?.salesChannel!, token.accessToken);

    if (!closeCaseResult.isSuccess) {
      const isCaseNotFound = closeCaseResult.errors?.includes('CASE_NOT_FOUND');
      openErrorNotification(isCaseNotFound!);
      setCaseInfo(null);
      return;
    }
    if (caseComplete.caseStatus === CaseStatus.Booked) {
      //If no booked slot is provided, then there was an error with the booking. Open the error notification that allows the user to get a new assignment case
      if (!bookedSlot) {
        openErrorNotification(true);
        return;
      }

      setBookedSlot(bookedSlot);
      setDisplayConfirmationPage(true);
    } else {
      if (userShouldBeRecorded) {
        setCaseStatus(caseComplete.caseStatus);
        mediaRecorder.current?.requestData();
      }
      await wait(1000);

      if (inputCaseComments) {
        const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
        await bookingPageService.addCaseComment(caseInfo?.caseId!, caseInfo?.opportunityId!, inputCaseComments, salesChannel, token.accessToken);
      }

      // send recording for all other cases except not reached
      if (!inboundCenter) {
        const nextCase = await bookingPageService.getOrAssignPriorityCase(token.accessToken, salesChannel!);
        handleCaseAssignemnt(nextCase);
        if (nextCase.isSuccess) {
          notification.open({
            message: strings.caseCompleteNotification,
            placement: 'topRight',
            type: 'success',
          });
        } else {
          notification.open({
            message: strings.assignNewCaseError,
            placement: 'topRight',
            type: 'error',
          });
        }
      } else {
        await onBackToInbound(closeCaseResult.isSuccess);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <Spin size="large" />
        <div className="loading-text" style={{ marginTop: '2vh' }}>
          Loading...
        </div>
      </div>
    );
  }

  let scripts;

  if (caseInfo) {
    scripts = <CaseScript salesScript={caseInfo.salesScript} salesObjections={caseInfo.salesObjections} resetPosition={!displayConfirmationPage} />;
  }

  if (displayConfirmationPage) {
    return (
      <>
        {header}
        <div className="bp-top-microphone">
          <OnlineRecordingBookingPage caseInfo={caseInfo} isAgentOnline={isOnline} isUserRecorded={userShouldBeRecorded} />
        </div>
        <div className="bp-layout">
          <div className="bp-main">
            <ConfirmationPage
              inboundCenter={inboundCenter}
              onBackToInbound={onBackToInbound}
              caseInfo={caseInfo!}
              bookedSlot={bookedSlot!}
              cancelledSlot={cancelledSlot}
              inputCaseComments={inputCaseComments}
              setInputCaseComments={setInputCaseComments}
              onOpenNextCase={confirmationPageOpenNextCase}
            />
          </div>
          <div className="bp-sider">{scripts}</div>
        </div>
      </>
    );
  }

  if (isOnline && caseInfo) {
    return (
      <>
        {header}
        <div className="bp-vertical-allignment">
          <div className="bp-top-microphone">
            <OnlineRecordingBookingPage caseInfo={caseInfo} isAgentOnline={isOnline} isUserRecorded={userShouldBeRecorded} />
          </div>
          <div className="bp-layout">
            <div className="bp-main">
              <OnlineBookingPage
                inboundCenter={inboundCenter}
                caseInfo={caseInfo}
                setCaseInfo={setCaseInfo}
                cancelledSlot={cancelledSlot}
                onCaseClosed={handleCaseClosed}
                inputCaseComments={inputCaseComments}
                setInputCaseComments={setInputCaseComments}
              />
            </div>
            <div className="bp-sider">{scripts}</div>
          </div>
        </div>
      </>
    );
  }

  if (isOnline && caseInfo === null && isLoading === false) {
    return (
      <>
        {header}
        <OnlineBookingNoCasesPage onTryFetchNextCase={onTryFetchNextCase} retryCountdown={retryCountdown!} />
      </>
    );
  }

  if (!isOnline) {
    return (
      <>
        {header}
        <OfflineBookingPage />
      </>
    );
  }

  return (
    <>
      {header}
      <Alert type="error" message="The application reached an unexpected state, please contact support." />
    </>
  );
};
