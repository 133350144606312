import { Button, Input, Modal, Radio, RadioChangeEvent, Tooltip } from 'antd';
import React, { useState } from 'react';
import { strings } from '../../../lang';
import TextArea from 'antd/es/input/TextArea';
import { PhoneOutlined } from '@ant-design/icons';
import { set } from 'date-fns';

export interface WrongPhoneNumberModalProps {
  phoneNumber: string;
  leadPhoneNumber: string;
  open: boolean;
  onOk: (message: string) => void;
  showModal: (val: boolean) => void;
  onUpdatePhoneNumber: (phoneNumber: string) => void;
}

const WrongPhoneNumberModal: React.FC<WrongPhoneNumberModalProps> = ({ open, phoneNumber: phoneNumber, leadPhoneNumber, onOk, showModal, onUpdatePhoneNumber }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [showTextInput, setShowTextInput] = useState<boolean>(false);
  const [freeText, setFreeText] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLeadPhone, setShowLeadPhone] = useState<boolean>(leadPhoneNumber !== '' && leadPhoneNumber !== phoneNumber);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await onOk(!freeText ? `Wrong Phone Number: ${selectedOption!}` : `Wrong Phone Number: ${selectedOption}${freeText!}`);
    } finally {
      setIsLoading(false);
      setFreeText(null);
      setShowTextInput(false);
      setSelectedOption(null);
    }
  };

  const handleCancel = () => {
    showModal(false);
    setSelectedOption(null);
    setShowTextInput(false);
    setFreeText(null);
    setShowLeadPhone(leadPhoneNumber !== '' && leadPhoneNumber !== phoneNumber);
  };

  const onRadioChange = (e: RadioChangeEvent) => {
    setShowTextInput(e.target.id === '4');
    setSelectedOption(e.target.value);
  };

  const onTextFieldChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFreeText(e.target.value);
  };

  const updatePhoneNumber = async () => {
    setIsLoading(true);
    await onUpdatePhoneNumber(leadPhoneNumber);
    setIsLoading(false);
    handleCancel();
    setShowLeadPhone(false);
  }

  const updateWrongPhoneNumberBody = () => {
    return (
      <div
        style={{ marginBottom: '20px' }}
      >
        <p>{strings.updateWrongPhoneNumber?.body}</p>
        <a href={`tel:${leadPhoneNumber}`}
          style={{ fontWeight: 'bold', textDecoration: 'underline', fontSize: '16px', color: '#1531f0' }}
        >
          <PhoneOutlined /> {leadPhoneNumber}
        </a>
      </div>
    );
  }

  const closeCaseWrongPhoneNumberBody = () => {
    return (
      <>
        <p>* {strings.wrongPhoneNumber.prompt}</p>
        <Radio.Group onChange={onRadioChange}>
          <Radio id="1" value="Number looks invalid (e.g. 000000000 or 123456789)" style={{ marginBottom: '5px' }}>
            {strings.wrongPhoneNumber.reasons[0]}
          </Radio>
          <Radio id="2" value="No connection under this number" style={{ marginBottom: '5px' }}>
            {strings.wrongPhoneNumber.reasons[1]}
          </Radio>
          <Radio id="3" value="Wrong Person (e.g. did not know about Enpal)" style={{ marginBottom: '5px' }}>
            {strings.wrongPhoneNumber.reasons[2]}
          </Radio>
          <Radio id="4" value="Other: " style={{ marginBottom: '5px' }}>
            {strings.wrongPhoneNumber.reasons[3]}
          </Radio>
        </Radio.Group>
        {showTextInput && <TextArea placeholder={strings.wrongPhoneNumber.pleaseSpecifyReason} onChange={onTextFieldChanged} />}
      </>
    )
  }

  return (
    <Modal
      title={strings.wrongPhoneNumber.title}
      width={600}
      centered
      closable={false}
      destroyOnClose={true}
      keyboard={false}
      maskClosable={false}
      open={open}
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
          <Button
            key="cancel"
            onClick={handleCancel}
            disabled={isLoading}
          >
            {strings.updateWrongPhoneNumber.cancel}
          </Button>
          {showLeadPhone ? (
            <>
              <Tooltip title={strings.updateWrongPhoneNumber.updateWithnewNumberTooltip}>
                <Button
                  loading={isLoading}
                  onClick={async () => await updatePhoneNumber()}
                >
                  {strings.updateWrongPhoneNumber.updateWithNewNumber}
                </Button>
              </Tooltip>
              <Button
                disabled={isLoading}
                type='primary'
                onClick={() => setShowLeadPhone(false)}
              >
                {strings.updateWrongPhoneNumber.newNumberIncorrect}
              </Button>
            </>
          )
            : <Button
              key="ok"
              type="primary"
              onClick={handleSubmit}
              title={strings.wrongPhoneNumber.buttonClarification}
              loading={isLoading}
              disabled={(!selectedOption && !showTextInput) || (showTextInput && !freeText)}
            >
              {strings.wrongPhoneNumber.saveAndOpenNextCase}
            </Button>}
        </div>
      }
    >
      {showLeadPhone
        ? updateWrongPhoneNumberBody()
        : closeCaseWrongPhoneNumberBody()}
    </Modal>
  );
};

export default WrongPhoneNumberModal;
